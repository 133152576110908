































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

import { notifyError, notifySuccess } from "@/components/Notification";
import { mapActions, mapState } from "vuex";
import rules from "@/components/account/rules";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "btn-create-medical-location",
  data() {
    return {
      dialog: false,
      formValid: false,
      name: "",
      rules,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["loading"]),
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actCreateNomenclator"]),
    create(): void {
      this.actCreateNomenclator({
        name: this.name,
        entity: "MedicalLocation",
      })
        .then(() => {
          notifySuccess(`<b>${this.name}</b> ${this.$t("createdLabel")}`);
          this.dialog = false;
          this.name = "";
          this.$emit("created");
        })
        .catch((error: AxiosError) => {
          notifyError(
            error.response?.data.message || error.response?.data.details
          );
        });
    },
  },
});
